
h2, h3 {

  font: 900 1.4em / 1.2em "Chivo", sans-serif, sans-serif;
  font-family: "Chivo", sans-serif;
  font-size: 42px;
  line-height: 42px;
}

h1 {
  font-family: "Chivo", sans-serif;
  font-weight: 900;
  font-size: 52px;
  line-height: 58px;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.custom-margin-top {
  line-height: 50px;
}

.logo-container {
  position: relative;
  z-index: 3;
}

.calendar-section {
  min-height: 200px;
}


.calendar-wrapper {
  min-height: 400px; 
  height: auto;
  padding: 2rem 0;
}


.calendar-ratio {
  height: 100%;
  min-height: inherit;
}

/* Only enforce aspect ratio when container is wide enough */
@media (min-width: 650px) {
  .calendar-ratio::before {
    padding-top: 45%; /* 16:9 aspect ratio */
  }
}

/* For smaller screens, let min-height take precedence */
@media (max-width: 651px) {
  .calendar-ratio::before {
    padding-top: 0;
  }
}
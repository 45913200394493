.nav-item {
  margin: 0 0.5rem;
  padding: 0.5rem 0;
  color: black;
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #0960fa;
  transition: all 0.3s ease-in-out;
}

.nav-item:hover::after {
  width: 100%;
  left: 0;
}

.nav-item:hover {
  color: #0960fa !important;
}

/* New styles for active state */
.nav-item.active {
  color: #0960fa !important;
}

.nav-item.active::after {
  width: 100%;
  left: 0;
}

/* Add this to override Bootstrap's default styles */
.navbar-nav .nav-link {
  padding: 0.5rem 1rem;
}

/* Ensure the underline spans the full width of the nav item */
.navbar-nav .nav-item {
  display: flex;
  align-items: center;
}


.flag-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease-in-out;
}

.flag-toggle:hover {
  transform: scale(1.1);
}

.flag-icon {
  width: 23px; /* Adjust for size */
  height: 15x;
  display: block;
}